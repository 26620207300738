import { getBannerLayers } from "../banners-storage/utils";
import { BannerPickedLayers } from "../banners-storage/types";
import { ImageBase64URIStorage } from "./index";

export async function preloadBannerFirstImages(banner: BannerPickedLayers): Promise<void> {
    // const layers = getBannerLayers(banner);
    // const functions: Promise<void>[] = [];
    //
    // for (const layer of layers) {
    //     for (const key in layer.images) {
    //         if (key.indexOf('_first') < 0) {
    //             continue;
    //         }
    //
    //         functions.push(
    //             (async () => {
    //                 layer.images[key] = await (
    //                     new ImageBase64URIStorage()
    //                         .setSourceUrl(layer.images[key])
    //                         .get()
    //                 );
    //             })()
    //         );
    //     }
    // }
    //
    // await Promise.all(functions);
}

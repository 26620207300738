//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { preloadBannerFirstImages } from "~/service/storages/image-base64-uri-storage/utils";

export default {
    name: "LendingProducts",
    props: ["lendingData"],
    data() {
        return {
            isMobile: false,
            openTabs: [],
        };
    },
    computed: {
        ...mapState("products", { catalog_products: "products" }),
        bannerLayer() {
            return this.lendingData?.banner?.layers[0];
        },
        lendingProducts() {
            return this.lendingData.products?.filter((landingProduct) => {
                return this.catalog_products?.find((catalogProduct) => catalogProduct.id == landingProduct.id);
            });
        },
    },
    mounted() {
        if (window.innerWidth <= 767) {
            this.isMobile = true;
        }

        this.$nextTick(() => {
            const faqSection = document.querySelector("#faq");
            const expandBlock = document.querySelector(".expand-block");
            if (expandBlock && faqSection) {
                faqSection.appendChild(expandBlock);
            }
        });
    },
    methods: {
        blurImg(img) {
            if (!img) {
                return null;
            }

            if (this.isMobile && img.mobile_first) {
                return `url(${img.mobile_first})`;
            }

            if (img.desktop_first) {
                return `url(${img.desktop_first})`;
            }

            return null;
        },
        imagePos(horiztal, vertical) {
            const horizontalAlign = horiztal || "center";
            let varticalAlign;
            if (!vertical || vertical === "middle") {
                varticalAlign = "center";
            } else {
                varticalAlign = vertical;
            }

            return `${horizontalAlign} ${varticalAlign}`;
        },
        loadImg(event) {
            event.target.classList.add("loaded");
        },
        toogleTab(idx) {
            if (this.openTabs.includes(idx)) {
                this.openTabs = this.openTabs.filter((item) => item !== idx);
            } else {
                this.openTabs.push(idx);
            }
        },
        showTab(idx) {
            return this.openTabs.includes(idx);
        },
    },
};
